import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
import style from "../../pages/admin/studios/studio.module.css";
import { errorAlert } from "../alert/Alert";

const studioAmenitiesList = [
  "Wifi",
  "AC",
  "DJ",
  "Piano",
  "Drum",
  "Car Parking",
  "Banjo",
  "Rode NT1",
  "Shure SM7B",
  "AKG C214",
  "Shure SM58",
  "Shure SM57",
  "Neumann TLM103",
  "Neumann TLM102",
  "AKG C414B ULS",
  "SE Electronics RNR1",
  "Logic Pro X",
  "Steinberg Nuendo",
  "Steinberg Cubase",
];

function MultipleSelect({
  selectedItems = [],
  setSelectedItems = () => {},
  error,
  onBlur,
  name,
  touched = false,
  limit = 10,
}) {
  const [items, setItems] = useState(studioAmenitiesList);
  const [data, setData] = useState("");
  const inputRef = useRef(null);

  // Filter out selected items from available options
  const filteredAmenities = items.filter((o) => !selectedItems.includes(o));

  const onNameChange = (event) => {
    setData(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (data.trim() && !items.includes(data.trim())) {
      const newItem = data.trim();
      setItems([...items, newItem]);
      setData("");
    }
    // Focus input after adding
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleChange = (newSelectedItems) => {
    if (newSelectedItems.length > limit) {
      errorAlert(`You can select up to ${limit}  amenities only.`);
      return;
    }
    setSelectedItems(newSelectedItems);
  };

  return (
    <>
      <div className={style.customInput}>
        <label htmlFor="Amenities">Amenities</label>
        <Select
          id="Amenities"
          mode="multiple"
          onBlur={onBlur}
          name={name}
          placeholder="Select one or more Amenities"
          style={{ minHeight: "4.5vh" }}
          value={selectedItems}
          onChange={handleChange}
          options={filteredAmenities.map((item) => ({
            value: item,
            label: item,
          }))}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space key="input-button-space" style={{ padding: "0 8px 4px" }}>
                <Input
                  key="select-input"
                  placeholder="Please enter item"
                  ref={inputRef}
                  value={data}
                  onChange={onNameChange}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent key events from affecting the dropdown
                />
                <Button
                  key="select-button"
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={addItem}
                >
                  Add item
                </Button>
              </Space>
            </>
          )}
        />
      </div>
      {error && touched && Array.isArray(error) ? (
        error.map((errObj, index) => (
          <div key={index}>
            {Object.entries(errObj).map(([key, message]) => (
              <p className={style.error} key={key}>
                {message}
              </p>
            ))}
          </div>
        ))
      ) : error && touched && typeof error === "string" ? (
        <p className={style.error}>{error}</p>
      ) : null}
    </>
  );
}

export default MultipleSelect;
