import * as Yup from "yup";

export const armSchema = Yup.object().shape({
  userName: Yup.string().required("Full Name is required").min(4, "Too Short!"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  permission: Yup.array().of(Yup.string()).min(1, "Permission is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "password too short"),
  mobile: Yup.number()
    .typeError("Mobile number must be a number")
    .required("Mobile is required")
    .test(
      "len",
      "Mobile number must be exactly 10 digits",
      (val) => val && val.toString().length === 10
    ),
});

export const studioPartner = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .min(3, "Too Short!"),
  lastName: Yup.string().required("Last Name is required").min(1, "Too Short!"),
  studioId: Yup.string().required("Studio id is required").min(4, "Too Short!"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .required("Mobile is required")
    .min(10, "Mobile number should be at least  10 digit ")
    .max(10, "Mobile number should be at most  10 digit "),
  dob: Yup.string().required("Date of birth is required"),
});

export const bannerSchema = Yup.object().shape({
  banner_redirect: Yup.string()
    .required("Redirect type is required")
    .oneOf(["external", "in-app"], "Invalid redirect type"),
  photoURL: Yup.string().required("Banner Image  is required"),
  name: Yup.string().required("Banner Name  is required"),
  banner_type: Yup.string().required("Banner Type  is required"),
  active: Yup.number()
    .required("Status   is required")
    .transform((value, originalValue) => {
      return Number(originalValue);
    }),
  stage: Yup.number()
    .required("Stage   is required")
    .transform((value, originalValue) => {
      return Number(originalValue);
    }),

  redirectURL: Yup.string().when(
    "banner_redirect",
    (banner_redirect, schema) => {
      if (banner_redirect[0] === "external") {
        return schema
          .required("Redirect URL is required")
          .url("Invalid external URL")
          .min(2, "Too Short!");
      } else {
        return Yup.string().nullable();
      }
    }
  ),

  // banner_type: Yup.string().when(
  //   "banner_redirect",
  //   (banner_redirect, schema) => {
  //     if (banner_redirect[0] === "in-app") {
  //       return schema.required("Banner type is required");
  //     } else {
  //       return Yup.string().nullable();
  //     }
  //   }
  // ),

  forr: Yup.string().when("banner_redirect", (banner_redirect, schema) => {
    if (banner_redirect[0] === "in-app") {
      return schema.required("Particular Studio   is required");
    } else {
      return Yup.string().nullable();
    }
  }),

  entity_id: Yup.string().when(["banner_redirect", "forr"], (Arr, schema) => {
    // console.log(Arr, "forr");

    if (Arr[0] === "in-app" && Arr[1] === "page") {
      return schema.required(
        "Studio name is required for specific destinations"
      );
    } else {
      return Yup.string().nullable();
    }
  }),
});

export const DiscountSchema = Yup.object().shape({
  discountName: Yup.string().required("Discount Name is required"),
  discountPercentage: Yup.number()
    .required("Discount Percentage is required")
    .min(0, "Percentage must be at least 0")
    .max(100, "Percentage must be at most 100"),
  couponCode: Yup.string().required("Coupon Code is required"),
  discountType: Yup.number().required("Discount Type is required"),
  maxCapAmount: Yup.number()
    .required("Max Cap Amount is required")
    .min(0, "Amount must be at least 0"),
  description: Yup.string().required("Description is required"),
  usersList: Yup.array()
    .of(Yup.string())
    .when("discountType", (discountType, schema) => {
      // console.log("discountType=========>", discountType);
      if (parseInt(discountType[0]) === 4) {
        return schema.required("Special Users is required");
      } else {
        return Yup.array().nullable();
      }
    }),
  // discountDate: Yup.array()
  //   .of(Yup.string())
  //   .when("discountType", (discountType, schema) => {
  //     if (parseInt(discountType[0]) === 2) {
  //       return schema.required("Discount Date is required");
  //     } else {
  //       return Yup.array().nullable();
  //     }
  //   }),
  startDate: Yup.string().when("discountType", (discountType, schema) => {
    if (parseInt(discountType[0]) === 2) {
      return schema.required("Discount Start Date is required");
    } else {
      return Yup.string().nullable();
    }
  }),
  endDate: Yup.string().when("discountType", (discountType, schema) => {
    if (parseInt(discountType[0]) === 2) {
      return schema.required("Discount End Date is required");
    } else {
      return Yup.string().nullable();
    }
  }),
});

export const Categories = Yup.object().shape({
  categoryName: Yup.string().required("Category name is required"),
  bookingTypes: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("ID is required"),
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        seatOptions: Yup.array()
          .of(Yup.number().required("Seat option is required"))
          .min(2, "At least two seat options are required")
          .required("Seat options are required"),
      })
    )
    .min(1, "At least one booking type is required")
    .required("Booking type is required"),
  description: Yup.string().nullable(),
  iconUrl: Yup.string()
    .url("Invalid URL format")
    .required("Icon URL is required"),
});

export const CategoryEntity = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // area: Yup.number().required("Area is required"),
  pincode: Yup.string().required("Pincode is required"),
  state: Yup.string().required("State is required"),
  address: Yup.string().required("Address is required"),
  description: Yup.string().required("Description is required"),
  area: Yup.number().required("Area is required"),
  photos: Yup.array()
    .of(Yup.string().url("Each photo must be a valid URL"))
    .min(1, "At least one photo is required"),
  // capacity: Yup.number()
  //   .typeError("Capacity must be a number")
  //   .required("Capacity is required"),
  city: Yup.string().required("City is required"),
  mapLink: Yup.string()
    .url("Map link must be a valid URL")
    .required("Map link is required"),
  country: Yup.string()
    // .length(2, "Country code must be exactly 2 characters")
    .required("Country is required"),
  facilities: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("Facility ID is required"),
        name: Yup.string().required("Facility name is required"),
      })
    )
    .required("Facilities are required")
    .min(1, "At least one facility is required"),
  amenities: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("Amenity ID is required"),
        name: Yup.string().required("Amenity name is required"),
      })
    )
    .required("Amenities are required")
    .min(1, "At least one amenity is required"),
  listingDetails: Yup.array().of(
    Yup.object().shape({
      listingId: Yup.number().nullable(),
      listingName: Yup.string().required("Listing name is required"),
      listingPhotos: Yup.array().of(Yup.string().url("Invalid photo URL")),
      amenities: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required("Amenity ID is required"),
          name: Yup.string().required("Amenity name is required"),
        })
      )
      .required("Amenities are required")
      .min(1, "At least one amenity is required"),
      
      price: Yup.number()
        .typeError("Price must be a number")
        .required("Price is required"),
      basePrice: Yup.number().required("Base price is required"),
      aboutUs: Yup.string().required("Room details are required"),
      capacity: Yup.number()
      .typeError("Capacity must be a number")
      .required("Capacity is required"),
      // roomDetails: Yup.string().nullable(),
      discountPercentage: Yup.number()
        .min(0, "Discount percentage must be at least 0")
        .max(100, "Discount percentage must be at most 100")
        .typeError("Discount percentage must be a number")
        .required("Discount percentage is required"),
      // listingArea: Yup.number().required("Listing area is required"),
      // approval: Yup.string()
      //   .oneOf(
      //     ["Instant", "Request"],
      //     "Approval must be 'Instant' or 'Request'"
      //   )
      //   .default("Instant"),
      bookingTypes: Yup.array()
        .required("Booking types are required"),
      // bookingTypes: Yup.array()
      // bookingConfirmation: Yup.string().required(
      //   "Booking confirmation is required"
      // ),
      generalTime: Yup.object().shape({
        startTime: Yup.string().required("Start time is required"),
        endTime: Yup.string().required("End time is required"),
      }),
      seats: Yup.number()
        
      .when("bookingTypes", (bookingTypes, schema) => {
        if(bookingTypes?.[0]?.[0]?.value === "b3") {
          // console.log("bookingTypes?.[0]?.[0]?.value === ",bookingTypes?.[0]?.[0]?.value === "b3");
          return schema.required("Seats are required when 'Event' is selected").min(1, "Seats must be at least 1");
        } else {
          // console.log("bookingTypes?.[0]?.[0]?.value === elseeee ",bookingTypes?.[0]?.[0]?.value === "b3");
          return schema.nullable();
        }
       
      }),
    sessionHours: Yup.number()
      
      .when("bookingTypes", (bookingTypes, schema) => {
        if (bookingTypes?.[0]?.[0]?.value === "b2" || bookingTypes?.[0]?.[1]?.value === "b2") {
          console.log("bookingTypes?.[0]?.[0]?.value ===b2 truee ",bookingTypes);
          return schema.required("Session hours are required").min(1, "Session hours must be at least 1").max(24, "Session hours must be at most 24");
        } else {
          return schema.nullable();
        }
      
      }),
      bookingDays: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().nullable(),
            day: Yup.string().nullable(),
            startTime: Yup.string().nullable(),
            endTime: Yup.string().nullable(),
          })
        )
        .required("Booking days are required")
        .min(1, "At least one booking day is required"),
    })
  ),
  isActive: Yup.boolean().required("Active status is required"),
  // minPrice: Yup.object().shape({
  //   price: Yup.number().nullable().typeError("Price must be a number"),
  //   basePrice: Yup.number().nullable(),
  //   discountPercentage: Yup.number()
  //     .nullable()
  //     .typeError("Discount percentage must be a number"),
  // }),
  team: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required("Team member ID is required"),
      imgUrl: Yup.string().nullable().url("Photo must be a valid URL"),
      name: Yup.string().required("Team member name is required"),
      designation: Yup.string().required("Team member designation is required"),
    })
  ),
});
