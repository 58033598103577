import { errorAlert } from "../components/alert/Alert";
import api from "./api"

 class categoriesApi { 

    addNewCategories = async (data) => {
        // let {studioId,roomId,bookingDate,bookingHours} = slotData
        
      
        const response = await api.post(`/category`, 
          data
        );
        console.log("res ===>", response.data)
        if(!response.data.status){
      
          errorAlert(response.data.message)
        }
        return response.data;
        
       };

       getAllCategories = async (limit = 8 ,pageCount,active) => {
        const response = await api.get(`/category`,{
            params: {
                limit: limit,
                page :pageCount,
                // active: active
            }
        });
        console.log("res ===>", response.data)
        if(!response.data.status){
    
          errorAlert(response.data.message)
        }
        return response.data;
    
       };

       getCategoriesById = async (id) => {
        const response = await api.get(`/category`,{
            params: {
              id
                // active: active
            }
        });
        console.log("res ===>", response.data)
        if(!response.data.status){
    
          errorAlert(response.data.message)
        }
        return response.data;
    
       };

       getCategorieEntityById = async (id) => {
        const response = await api.get(`/category-entity`,{
            params: {
              id
                // active: active
            }
        });
        console.log("res ===>", response.data)
        if(!response.data.status){
    
          errorAlert(response.data.message)
        }
        return response.data;
    
       };

       getCategoriesEntityById = async (categoryId,page,limit=8) => {
        const response = await api.get(`/category-entity`,{
            params: {
              categoryId,
              page,
              limit,

                // active: active
            }
        });
        console.log("res ===>", response.data)
        if(!response.data.status){
    
          errorAlert(response.data.message)
        }
        return response.data;
    
       };

       addNewEntity = async (data) => {
        // let {studioId,roomId,bookingDate,bookingHours} = slotData
        
      
        const response = await api.post(`/category-entity`, 
          data
        );
        console.log("res ===>", response.data)
        if(!response.data.status){
      
          errorAlert(response.data.message)
        }
        return response.data;
        
       };

       addNewCategoriesEntity = async (data) => {
        // let {studioId,roomId,bookingDate,bookingHours} = slotData
        
      
        const response = await api.post(`/category-entity`, 
          data
        );
        console.log("res ===>", response.data)
        if(!response.data.status){
      
          errorAlert(response.data.message)
        }
        return response.data;
        
       };

       updateCategoryEntity = async (id, data) => {

        try {
          const response = await api.put(`api/category-entities/${id}`,
          data
          );
          const {status} = response.data
          console.log("postdata ===>", response.data)
          if(!response.data.status){
    
            errorAlert(response.data.message)
          }
          return response.data;
          
        } catch (error) {
          console.log(error)
    
          return error
        }
          
       
         };
       updateCategory = async (id, data) => {

        try {
          const response = await api.put(`category/${id}`,
          data
          );
          const {status} = response.data
          console.log("postdata ===>", response.data)
          if(!response.data.status){
    
            errorAlert(response.data.message)
          }
          return response.data;
          
        } catch (error) {
          console.log(error)
    
          return error
        }
          
       
         };

         getAllBookings = async (limit = 8 ,pageCount,active) => {
          const response = await api.get(`/category`,{
              params: {
                  limit: limit,
                  page :pageCount,
                  // active: active
              }
          });
          console.log("res ===>", response.data)
          if(!response.data.status){
      
            errorAlert(response.data.message)
          }
          return response.data;
      
         };
         categoryStatus = async(catObjId ) => {
          const response = await api.patch(`/category/active`,
            {
              catObjId
            }
          );
          console.log("res ===>", response.data)
          if(!response.data.status){

            errorAlert(response.data.message)
          }
          return response.data;

         }
         categoryStageUpdate = async(catObjId) => {
          const response = await api.patch(`/category/updateStage`,catObjId
            
          )
          console.log("res ===>", response.data)
          if(!response.data.status){
            errorAlert(response.data.message)
          }return response.data;
        }

        filterData = async (allfilterData) => {

          const filteredObject = {};
          for (const key in allfilterData) {
            if (allfilterData[key]) {
              filteredObject[key] = allfilterData[key];
            }
          }
            filteredObject.limit = filteredObject.limit || 7;
      
            const response = await api.get("/category-entity",{ 
                params: filteredObject
            });
            const {status} = response.data
            // console.log("res ===>", response.data)
            if(!response.data.status){
      
              errorAlert(response.data.message)
            }
            return response.data;
           };
 }


 export default new categoriesApi();